import { ReactNode } from 'react'

export default function LeagueRanking({ children }: { children?: ReactNode | ReactNode[] }) {
  return <div className='league-ranking' >
    {children}
  </div>
}

const LeagueRankingHeader = ({ omit }: { omit?: string[] }) => <div className='league-ranking-header'>
  <div className='league-ranking-header__position'>POS.</div>
  <div className='league-ranking-header__player'>PLAYER.</div>
  <div className='league-ranking-header__score'>SCORE</div>
  {!omit?.includes('prizes') && <div className='league-ranking-header__prizes'>PRIZES</div>}
</div>

const LeagueRankingBody = ({ children }: { children: ReactNode | ReactNode[] }) => <div className='league-ranking-body'>
  {children}
</div>

LeagueRanking.Header = LeagueRankingHeader
LeagueRanking.Body = LeagueRankingBody
