import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LeagueReward } from 'domain/league.type'
import { connectedWallet } from 'selectors/selectors'
import { useRecoilValue } from 'recoil'
import LeagueRankingItemPrize from './league-ranking-item-prize/league-ranking-item.prize'

type LeagueRankingItemProps = {
  score: number
  position: number
  player: string
  address: string
  avatar: string
  rewards: LeagueReward[][]
}

function getPositionStyles(pos: number) {
  if (pos === 1) {
    return 'league-ranking-item--first'
  }
  if (pos === 2) {
    return 'league-ranking-item--second'
  }
  if (pos === 3) {
    return 'league-ranking-item--third'
  }
  return 'league-ranking-item--default'
}

const Avatar = (props: { avatar: string, size?: string, highlighted?: boolean }) => <div className='avatar-box'>
  {props.avatar
    ? <div className={`avatar-img ${props.highlighted ? 'highlighted' : ''}`} style={{ backgroundImage: `url(${props.avatar})`, width: props.size || '60px', height: props.size || '60px' }} />
    : <FontAwesomeIcon icon={faUserCircle as IconProp} size='4x' className='user-avatar-desktop' />}
</div>

export default function LeagueRankingItem(props: LeagueRankingItemProps) {
  const user = useRecoilValue(connectedWallet)
  // eslint-disable-next-line no-console
  return <div className={`league-ranking-item ${getPositionStyles(props.position)}`} >
    {props.position < 4 && <div className='league-ranking-item__overlay' />}
    <div className='league-ranking-item__position'>{props.position}</div>
    <div className='league-ranking-item__avatar'>
      <Avatar highlighted={user?.address === props.address} avatar={props.avatar} size={'60px'} />
    </div>
    <div className='league-ranking-item__player'>{props.player}</div>
    <div className='league-ranking-item__score'>{props.score}</div>
    {props.rewards.length > 0 && <div className='league-ranking-item__prize'>
      <LeagueRankingItemPrize position={props.position}
        rewards={props.rewards.length > 0 ? props.rewards[props.position - 1] : []} />
    </div>}
  </div>
}
