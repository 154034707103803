// noinspection JSUnusedGlobalSymbols

import axios from 'axios'
import { Tournament } from 'domain/tournament.model'
import { League, LeagueScores } from 'domain/league.type'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'
import getConfig from 'config.service'
import { ServiceConsumer, ServiceData } from 'infraestructure/services/service-consumer/service-consumer.service'
import { getHost } from '../infraestructure/services/host-consumer/host-consumer.service'
import freeRollIcon from '../assets/icons/icon-freeroll.png'
import ticketBIcon from '../assets/icons/icon-ticketb.png'

const baseUrl = `${getHost('tournaments')}/api/v1/tournaments`
export type SubscriptionState = 'subscribed' | 'not-subscribed' | 'in-progress'
export default abstract class TournamentsRepository {
  static async getAll(status?: string): Promise<any> {
    try {
      const token = ValidateIfTokenIsPresent()
      const res = await axios.get(baseUrl, {
        params: {
          status,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return res.data?.tournaments ?? []
    } catch (error: any) {
      // eslint-disable-next-line
      console.error(error)
      return []
    }
  }

  static async getMonthlyRanking(): Promise<any> {
    const token = ValidateIfTokenIsPresent()
    const url = `${getConfig().hostURL}/api/v1/matching/matches/monthlyRanking`
    const { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  }

  static async getDeckState(ownerId: string, deckId: string): Promise<{ isBusy: boolean, tournaments: Tournament[] }> {
    const token = ValidateIfTokenIsPresent()
    const resp = await axios.get(`${baseUrl}/deck-state/${ownerId}/${deckId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return resp.data
  }

  static async getById(id: string): Promise<any> {
    const url = `${baseUrl}/${id}`
    try {
      const token = ValidateIfTokenIsPresent()
      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return res.data?.tournament
    } catch (error: any) {
      // eslint-disable-next-line
      console.error(error)
      return []
    }
  }

  static async subscribe(tournamentId: string, playerId: string, deckId: string): Promise<any> {
    const url = `${baseUrl}/${tournamentId}/subscriptions`
    const token = ValidateIfTokenIsPresent()
    const body = {
      playerId,
      deckId,
      tournamentId,
    }
    const res = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data?.tournament
  }

  static async quit(tournamentId: string, playerId: string): Promise<any> {
    const url = `${getHost('tournaments')}/api/v1/tournaments/${tournamentId}/unsubscribe/${playerId}`
    const token = ValidateIfTokenIsPresent()
    const res = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data?.tournament
  }

  static async getLeagues(): Promise<League[]> {
    const url = `${baseUrl}/leagues/all-leagues`
    const leagues = await axios.get<League[]>(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return leagues.data
  }

  static async getLeagueScores(league: string): Promise<LeagueScores> {
    const url = `${baseUrl}/leagues/${league}/today-scores`
    // const url = `http://localhost:8085/api/v1/tournaments/leagues/${league}/today-scores`
    const scores = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return scores.data
  }

  public static getSubscriptionState(tournamentId: string, playerId: string): Promise<{
    state: SubscriptionState,
    message?: string,
  }> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${baseUrl}/subscriptions/${tournamentId}/${playerId}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    return ServiceConsumer.get<{
      state: SubscriptionState,
      message?: string,
    }>(requestData)
  }

  static async goNextState(tournamentId: string, currentState: string): Promise<Tournament> {
    const action = this.getActionByState(currentState)
    const url = `${baseUrl}/${tournamentId}/${action}`
    const token = ValidateIfTokenIsPresent()
    const res = await axios.post(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res.data?.tournament) throw new Error('Invalid tournament')
    return res.data?.tournament
  }

  private static getActionByState(currentState: string): string {
    switch (currentState) {
      case 'CREATED': return 'announce'
      case 'ANNOUNCED': return 'publish'
      case 'PUBLISHED': return 'start'
      default: throw new Error('Invalid state')
    }
  }

  static getTicketIcon(ticketID: string | undefined): string {
    if (!ticketID) return freeRollIcon
    switch (ticketID) {
      case '1':
        return freeRollIcon
      case '2':
        return ticketBIcon
      default:
        return freeRollIcon
    }
  }

  static getTicketName(ticketID: string | undefined): string {
    if (!ticketID) return ''
    const ticketNames = new Map<string, string>()
    ticketNames.set('1', 'TOURNAMENT TICKET')
    ticketNames.set('2', 'QUALIFIER TICKET')
    ticketNames.set('3', 'HALLOWEEN TICKET')
    ticketNames.set('4', 'CONTACT SUPPORT')
    ticketNames.set('5', 'STREET DAILY PASS')
    ticketNames.set('6', 'STREET WEEK PASS')
    ticketNames.set('7', 'STREET MONTH PASS')
    ticketNames.set('8', 'STREET DAILY TICKET')
    ticketNames.set('9', 'STREET WEEK TICKET')
    ticketNames.set('10', 'STREET MIDWAY TICKET')
    ticketNames.set('11', 'STREET MONTH TICKET')
    ticketNames.set('12', 'PLAYGROUND DAILY PASS')
    ticketNames.set('13', 'PLAYGROUND WEEK PASS')
    ticketNames.set('14', 'PLAYGROUND MONTH PASS')
    ticketNames.set('15', 'PLAYGROUND Daily TICKET')
    ticketNames.set('16', 'PLAYGROUND Week TICKET')
    ticketNames.set('17', 'PLAYGROUND Midway TICKET')
    ticketNames.set('18', 'PLAYGROUND MONTH TICKET')
    ticketNames.set('19', 'UNDERGROUND COPPER')
    ticketNames.set('20', 'UNDERGROUND BRONZE')
    ticketNames.set('21', 'UNDERGROUND SILVER')
    ticketNames.set('22', 'UNDERGROUND GOLD')
    ticketNames.set('23', 'UNDERGROUND DIAMOND')
    ticketNames.set('24', 'OWNER RECRUITMENT TICKET')
    ticketNames.set('25', 'OWNER FINAL TRIAL TICKET')

    return ticketNames.get(ticketID) || ''
  }
}
